import React from 'react'
import "./../Loader/Loader.css"
function Loader() {
  return (
    <div className="d-flex justify-content-center">
    <div className="loader"></div>
  </div>
  )
}

export default Loader
